import { BrowserRouter, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import responseInterceptors from "./apis/axios";
import Header from "./components/Header/Header";
import LoginPage from "./components/Login/LoginPage";
import { QueryClientProvider, QueryClient } from "react-query";
import ErrorPage from "./common/ErrorPage";
import AssignCandidate from "./components/AssignCandidate/AssignCandidate";
import RoleList from "./components/RoleList/Rolelist";
import Assessment from "./components/AssessmentList/index";
import AssessmentList from "./components/AssessmentList/AssessmentList";
import CandidateList from "./components/CandidateList/CandidateList";
import Dashboard from "./components/dashboard";
import Home from "./components/Home/home";
import { useState } from "react";
import AssessmentDetails from "./components/AssessmentDetails/AssessmentDetails";
import LogoutPage from "./common/LogoutPage";
import toast, { Toaster } from "react-hot-toast";
import ReportCard from "./components/ReportCard/ReportCard";
import ReportCardSideBar from "./components/ReportCardSideBar/ReportCardSideBar";
responseInterceptors();

const App = () => {
  const queryClient = new QueryClient();
  const navigate = useNavigate();

  // Check authentication status from localStorage on initial load
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem("isAuthenticated") === "true";
  });

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.clear();
    toast.success("Logout Successfully");
    navigate("/"); // Navigate to the logout page
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
    toast.success("Login Successfully");
    localStorage.setItem("isAuthenticated", "true");
  };

  return (
    <QueryClientProvider client={queryClient}>
           <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "18px",
            padding: "16px",
            minWidth: "300px",
          },
        }}
      />
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/dashboard" />
            ) : (
              <LoginPage onLogin={handleLogin} />
            )
          }
        />
        {isAuthenticated && (
          <Route path="/" element={<Home onLogout={handleLogout} />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="roles" element={<RoleList />} />
            <Route path="assessments" element={<Assessment />}>
              <Route index element={<AssessmentList />} />
              <Route path="exam/:examId/assigncandidate" element={<AssignCandidate />} />
              <Route path="exam/:examId/assessmentdetail" element={<AssessmentDetails />} />
              <Route path="exam/:examId/candidate" element={<CandidateList />} />
              <Route  path="Reportcard" element={<ReportCardSideBar/>} />
            </Route>
            <Route path="candidates" element={<CandidateList />} />
            <Route path="exam/:examId/user/:userId/report" element={<ReportCard />} />
           
          </Route>
        )}
        <Route path="logout" element={<LogoutPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </QueryClientProvider>
  );
};

const RootApp = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default RootApp;

