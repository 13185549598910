import { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const AdminPopOver = ({logout}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
 const copyEmail=()=>{
  navigator.clipboard.writeText("contact@kodiva.ai");
  toast.success("Email copied to clipboard");
 }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
       <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "18px",
            padding: "16px",
            minWidth: "300px",
          },
        }}
      />
      <button
        onClick={handleClick}
        className={` px-2 py-2  flex flex-col justify-center items-center gap-2 rounded-md`}
      >
        <div>
          <img src="/images/Profile pic.png" width="30px" height="30px" />
        </div>
        {/* <div className="text-sm">Admin</div> */}
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography className="px-2 py-2 flex flex-col gap-4 max-w-2xl w-[300px]">
          <div className="flex flex-col gap-2 px-4 py-4 ">
            <p>contact@kodiva.ai</p>
            <p>9876543210</p>
            <hr className="border-t border-black opacity-20" />
            <button className="flex items-center gap-20"   onClick={copyEmail}>
              <div>Help and Support</div>
              
              <button 
                className="px-4 py-2 hover:bg-grayColor hover:px-6 hover:py-6 rounded-md group" 
              
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="group-hover:stroke-white"
                >
                  <g clip-path="url(#clip0_6430_22050)">
                    <path
                      d="M8.00016 14.6668C11.6821 14.6668 14.6668 11.6821 14.6668 8.00016C14.6668 4.31826 11.6821 1.3335 8.00016 1.3335C4.31826 1.3335 1.3335 4.31826 1.3335 8.00016C1.3335 11.6821 4.31826 14.6668 8.00016 14.6668Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.06006 5.99989C6.21679 5.55434 6.52616 5.17863 6.93336 4.93931C7.34056 4.7 7.81932 4.61252 8.28484 4.69237C8.75036 4.77222 9.1726 5.01424 9.47678 5.37558C9.78095 5.73691 9.94743 6.19424 9.94673 6.66656C9.94673 7.99989 7.94673 8.66656 7.94673 8.66656"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 11.3335H8.00667"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6430_22050">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              
            </button>
            <hr className="border-t border-black opacity-20" />
            <button className="flex items-center justify-between w-full" onClick={logout}>
              <div>Logout</div>
              <button  className="flex hover:bg-grayColor hover:px-6 hover:py-6 rounded-md ml-10">
                <div className="px-4 py-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6"
                    stroke="#E04C4C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6665 11.3332L13.9998 7.99984L10.6665 4.6665"
                    stroke="#E04C4C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 8H6"
                    stroke="#E04C4C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                </div>
              </button>
            </button>
          </div>
        </Typography>
      </Popover>
    </>
  );
};
export default AdminPopOver;
