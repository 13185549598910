import { useState, useEffect } from "react";
import StatusModal from "./StatusModal";
import ShortlistedApi from "../../apis/Shortlisted";

const StudentInfo= ({
    name,
    role,
    logoUrl,
    examUserId,
    token,
  }) => {
    const [isShortlisted, setIsShortlisted] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [status, setStatus] = useState(""); // Track current status
    const [newStatus, setNewStatus] = useState(""); // Track intended status to change
  
    const getStatus = async () => {
      try {
        const response = await ShortlistedApi.show({examUserId, token});
        if (response.data=== "Shortlisted") {
          setIsShortlisted(true);
          setIsRejected(false);
          setStatus("Shortlisted");
        } else if (response.data=== "Rejected") {
          setIsRejected(true);
          setIsShortlisted(false);
          setStatus("Rejected");
        }
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getStatus();
    }, []);
  
    const statusChangeFunction = async (newStatus) => {
      try {
        const response = await ShortlistedApi.update({examUserId, newStatus, token});
        if (response?.status===200) {
          console.log("hy");
          alert(`User successfully ${newStatus}`);
          localStorage.setItem(`status_${examUserId}`, newStatus);
          setStatus(newStatus);
          setIsShortlisted(newStatus === "Shortlisted");
          setIsRejected(newStatus === "Rejected");
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const handleStatusChange = async() => {
      if (newStatus !== status) {
        const success = await statusChangeFunction(newStatus);
        if (success) setIsModalOpen(false);
      } else {
        alert(`User is already ${status.toLowerCase()}.`);
      }
      setIsModalOpen(false);
    };
  
    const handleStatus = (selectedStatus) => {
      setNewStatus(selectedStatus); // Set the intended new status
      setIsModalOpen(true); // Open the modal for confirmation
    };
  
    return (
      <>
        <div className="flex flex-col justify-between sm:flex-row items-start p-4">
          <div className="items-center mb-2 sm:mb-0">
            <h2 className="text-xl font-semibold mr-4">{name}</h2>
            <div className="flex items-center mt-1 lg:mt-2 w-full">
              <img src={logoUrl} alt="Role logo" className="h-5 mr-2" />
              <span className="text-sm text-black flex break-words w-[80%]   lg:w-full ">
                {role.split(" ").slice(0, 3).join(" ") +
                  (role.split(" ").length > 3 ? "..." : "")}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-6">
            {/* Shortlist Button */}
            <div
              className={`${
                isShortlisted ? "bg-lightGreen" : "bg-grayColor"
              } text-forestGreen px-4 py-2 rounded-lg flex items-center mt-2 sm:mt-0 text-bold cursor-pointer`}
              onClick={() => handleStatus("Shortlisted")}
            >
              <span className="font-bold text-md ">
                {isShortlisted ? "Shortlisted" : "Shortlist"}
              </span>
            </div>
  
            {/* Reject Button */}
            <div
              className={`${
                isRejected ? "bg-lightRed" : "bg-grayColor"
              } text-redColor px-4 py-2 rounded-lg flex items-center mt-2 sm:mt-0 text-bold cursor-pointer`}
              onClick={() => handleStatus("Rejected")}
            >
              <span className="font-bold text-md ">
                {isRejected ? "Rejected" : "Reject"}
              </span>
            </div>
          </div>
          <StatusModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleStatusChange} // Trigger handleStatusChange on modal submission
            status={status} // Show the intended new status in modal
          />
        </div>
      </>
    );
  };
  
  export default StudentInfo;