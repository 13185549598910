import axios from 'axios';

const show=async({token,examId})=>{
    const url=`${process.env.REACT_APP_PUBLIC_URL}/exam/${examId}/candidates`;

    try {
        const response=await axios.get(url,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        })
        return response.data;
    } catch (error) {
        console.log();
        if(error.response.data.data.data==="NOT_FOUND_REQUEST"){
            return error.response.data.data.data||{};
        }
         console.log(error);
    }
}

const assignCandidateExamApi={show};
export default assignCandidateExamApi;