import { Outlet } from "react-router-dom"
import Header from "../Header/Header";


const Home=({onLogout})=>{
    return (
        <div className="flex">
            <div  className="fixed top-0 z-10">
            <Header onLogout={onLogout} />
            </div>
   

        <div className="flex mr-auto ml-auto">
        <div className="mt-6">
        <Outlet/>
        </div>
        </div>
            </div>


    )
};
export default Home;