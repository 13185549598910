

import axios from 'axios';

const fetch=async({examId,token,debounceOwnerEmail:sendMail,debounceUserEmail:email})=>{
    const url=`${process.env.REACT_APP_PUBLIC_URL}/exam/${examId}/sendReportCard`;
    try {
        const response=await axios.post(url,{
    userEmails:Array.isArray(email) ? email : email.split(',').map(e => e.trim()),
    sendToMail:sendMail
        },{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        })
    
        return response;
    } catch (error) {
        console.log(error);
        return error;
        
    }
}
const ReportCardGenerateapi={fetch};
export default ReportCardGenerateapi;