import { useQuery } from "react-query";
import roleListApi from "../../apis/roleListApi";
import useStore from "../../store/useStore";
import Loading from "../../common/Loading";
import ErrorPage from "../../common/ErrorPage";
import Header from "../Header/Header";
import NoData from "../../common/NoData";

const RoleList = () => {
 
  
  const token = useStore((state) => state.token);
  const fetchRoleList = async () => {
    const reposnse = await roleListApi.show(token);
    return reposnse; 
  };
  
  // React Query usage
  const { data, isLoading, isError } = useQuery({
    queryKey: ['rolelist'],
    queryFn: fetchRoleList, 
  });

  
if(isLoading) return <Loading/>
if(isError ) return <ErrorPage/>
  if(!data|| data==="user is not a part of organization") return <NoData/>
  return (
 
  
      <div
        className="flex  flex-col items-center justify-center gap-4 mt-2"
        style={{ width: "800px" }}
      >
        {/* <!-- heading --> */}
        <div className="text-2xl font-semibold">Role List</div>
        <nav className="flex w-full items-center justify-between gap-20 mt-4">
          {/* <!-- left side --> */}
          <div className="flex items-center gap-3">
            <div
              className="text-xl font-semibold"
            >
              Role({data.length||0})
            </div>
          </div>

          {/* <!-- right side -->  bg-[#F1C24D]*/}
          <div className="flex items-center gap-2 rounded-full bg-yellowColor  px-4 py-2">
            <div className="text-sm">Create Role</div>
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5880_21589)">
                  <path
                    d="M13.3356 17.5V15.8333C13.3356 14.9493 12.9844 14.1014 12.3593 13.4763C11.7342 12.8512 10.8863 12.5 10.0023 12.5H5.00228C4.11822 12.5 3.27038 12.8512 2.64526 13.4763C2.02013 14.1014 1.66895 14.9493 1.66895 15.8333V17.5"
                    stroke="#191C45"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.50228 9.16667C9.34323 9.16667 10.8356 7.67428 10.8356 5.83333C10.8356 3.99238 9.34323 2.5 7.50228 2.5C5.66133 2.5 4.16895 3.99238 4.16895 5.83333C4.16895 7.67428 5.66133 9.16667 7.50228 9.16667Z"
                    stroke="#191C45"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.8356 10.8333C16.7561 10.8333 17.5023 10.0871 17.5023 9.16667C17.5023 8.24619 16.7561 7.5 15.8356 7.5C14.9151 7.5 14.1689 8.24619 14.1689 9.16667C14.1689 10.0871 14.9151 10.8333 15.8356 10.8333Z"
                    stroke="#191C45"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.835 6.6665V7.49984"
                    stroke="#191C45"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.835 10.8335V11.6668"
                    stroke="#191C45"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.0023 7.9165L17.2773 8.33317"
                    stroke="#191C45"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.3939 10L13.6689 10.4167"
                    stroke="#191C45"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.0023 10.4167L17.2773 10"
                    stroke="#191C45"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.3939 8.33317L13.6689 7.9165"
                    stroke="#191C45"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5880_21589">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0.00195312)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </nav>
        {/* <!-- data will come --> */}
        <div className="flex w-full flex-col justify-center mt-4 gap-4">
        {data &&
          data.map((elem) => (
            <div key={elem.examId} className="flex w-full flex-col justify-center ">
          <div className="flex flex-col justify-center gap-3">
                {/* <!-- for each data -->

                 <!-- header part --> */}
                <div className="flex w-full items-center justify-between">
                  {/* <!-- left part --> */}
                  <div className="flex items-center gap-2">
                    <div className="text-md">{elem.name}</div>
                  </div>
                  {/* <!-- right part --> */}
                  <div className="flex items-center gap-2">
                    <div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.0015 13C12.5537 13 13.0015 12.5523 13.0015 12C13.0015 11.4477 12.5537 11 12.0015 11C11.4492 11 11.0015 11.4477 11.0015 12C11.0015 12.5523 11.4492 13 12.0015 13Z"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.0015 6C12.5537 6 13.0015 5.55228 13.0015 5C13.0015 4.44772 12.5537 4 12.0015 4C11.4492 4 11.0015 4.44772 11.0015 5C11.0015 5.55228 11.4492 6 12.0015 6Z"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.0015 20C12.5537 20 13.0015 19.5523 13.0015 19C13.0015 18.4477 12.5537 18 12.0015 18C11.4492 18 11.0015 18.4477 11.0015 19C11.0015 19.5523 11.4492 20 12.0015 20Z"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                {/* <!-- footer part --> */}
                <div className="flex w-full items-center justify-between">
                  {/* <!-- left part --> */}
                  <div className="flex items-center gap-2">
                    <div className="text-sm text-grayStroke">
                      {elem.createdAt}
                    </div>
                    <div>
                      <svg
                        width="1"
                        height="20"
                        viewBox="0 0 1 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.00146484"
                          width="1"
                          height="20"
                          rx="0.5"
                          fill="#383838"
                        />
                      </svg>
                    </div>
                    <div className="text-sm text-grayStroke">
                      Created By <span>{elem.createdBy}</span>
                    </div>
                  </div>
                  {/* <!-- right part --> */}
                  <div className="flex items-center gap-1 text-sm text-grayStroke">
                    <div>{elem.examCount}</div>
                    <div>assessment</div>
                  </div>
                </div>
                <hr className="border-t border-black opacity-20" />
              </div>
            </div>
          ))}
          </div>
      </div>
  
    
  );
};

export default RoleList;
