import { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import useDebounce from "../../hooks/useDebounce";
import toast, { Toaster } from "react-hot-toast";
import useStore from "../../store/useStore";
import ReportCardGenerateapi from "../../apis/ReportCardGenerateApi";
const ReportCardGeneratedPopOver = ({ examId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userEmail,setUserEmail]=useState('');
  const [ownerEmail,setOwnerEmail]=useState('');
  const token=useStore((state)=>state.token);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const debounceUserEmail=useDebounce(userEmail).trim();
  const debounceOwnerEmail=useDebounce(ownerEmail).trim();
  const sendReport=async({debounceOwnerEmail,debounceUserEmail})=>{
    try {
        const response=await ReportCardGenerateapi.fetch({token,examId,debounceUserEmail,debounceOwnerEmail});
        return response;
    } catch (error) {
      console.error(error);
    }
  }
const handleSubmit=async(e)=>{
    e.preventDefault();
    if (!debounceOwnerEmail || !debounceUserEmail) {
        toast.error('Please enter both owner and user email');
    }  else if (!/^\S+@\S+\.\S+$/.test(debounceUserEmail)) {
        toast.error('Invalid User email address');
    } else if (!/^\S+@\S+\.\S+$/.test(debounceOwnerEmail)) {
        toast.error('Invalid Interviewer email address');
    }
    else {
        try{
            const data=  await sendReport({debounceOwnerEmail,debounceUserEmail});
            console.log(data);
            if(data.status===200){
               toast.success(data.message);
            }
            else{
                toast.error("Failed to send Report Card");
            }
        }
        catch(error){
           
            toast.error(error.message);
        }
     
    
    }
    
  
}
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "18px",
            padding: "16px",
            minWidth: "300px",
          },
        }}
      />
      <button
        onClick={handleClick}
        className={examId===null?`bg-sunFlowerYellow rounded-full px-4 py-2 opacity-50 cursor-not-allowed `:`bg-sunFlowerYellow rounded-full px-4 py-2`}
        disabled={examId===null}
      >
        Send Report Card
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{ width: "400px" }}
          className="px-6 py-6 flex flex-col gap-4"
        >
          <input
            type="text"
            placeholder="Enter Candidate Email Address"
            className="border border-grayStroke outline-none rounded-md px-2 py-2"
            value={userEmail}
            onChange={(e)=>setUserEmail(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter Interviewer Email address"
            className="border border-grayStroke outline-none rounded-md px-2 py-2"
            value={ownerEmail}
            onChange={(e)=>setOwnerEmail(e.target.value)}
          />
          <button
            type="submit"
            className="bg-yellowColor rounded-full flex self-end px-4 py-2"
            onClick={handleSubmit}
          >
            Send Report Card
          </button>
        </Typography>
      </Popover>
    </>
  );
};
export default ReportCardGeneratedPopOver;
