import useStore from "../../store/useStore";
import { useQuery, useQueryClient } from "react-query";
import Loading from "../../common/Loading";
import ErrorPage from "../../common/ErrorPage";
import candidateListApi from "../../apis/candidatelist";
import { useCallback, useMemo, useState } from "react";
import moment from "moment/moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NoData from "../../common/NoData";
import useDebounce from "../../hooks/useDebounce";
import ShortlistedApi from "../../apis/Shortlisted";
import toast, { Toaster } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import ReportCardGeneratedPopOver from "./ReportCardGeneratedPopOver";

const CandidateList = () => {
  const [activeTab, setActiveTab] = useState(1);
  const location = useLocation();
  const { recentype, title, icon } = location.state || {};
  const { examId } = useParams();
  const token = useStore((state) => state.token);
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState("");
  const queryClient = useQueryClient();
  const [showExamDropdown, setShowExamDropdown] = useState(false);
  const [selectedExamId, setSelectedExamId] = useState(
    examId ? parseInt(examId) : null
  );
  const fetchCandidatelist = async () => {
    const reposnse = await candidateListApi.show(token);
    return reposnse.data;
  };
  const handleBackClick = () => {
    navigate("/assessments");
  };
  const { data, isLoading, isError } = useQuery({
    queryKey: ["candidatelist", examId],
    queryFn: fetchCandidatelist,
    refetchInterval: 60000,
    refetchOnWindowFocus: true,
  });
  const changeStatus = useCallback(async (examUserId, status) => {
    try {
      //   const data=await ShortlistedApi.show({token,examUserId});
      //  console.log(data);
      await ShortlistedApi.update({ examUserId, token, newStatus:status });

      toast.success("Candidate status updated!");
      queryClient.invalidateQueries(["candidatelist", examId]);
    } catch (error) {
      //  toast.error(error);
    }
  }, []);
  const debounceSearchKey = useDebounce(searchKey);

  const filteredCandidates = useMemo(() => {
    if (!data || !selectedExamId) return data;
    return data.filter((candidate) => candidate.exam?.id === selectedExamId);
  }, [data, selectedExamId]);

  const fetchCandidateList = useMemo(() => {
    if (!filteredCandidates) return []; // Handle case where data is null or undefined

    let filteredData = filteredCandidates; // Create a new array to avoid mutating the original

    // Handle search filtering
    if (debounceSearchKey.trim()) {
      const searchTerm = debounceSearchKey.trim().toLowerCase();
      const isEmailSearch = searchTerm.includes("@");

      filteredData = filteredData.filter((candidate) => {
        if (isEmailSearch) {
          return candidate.user?.email?.toLowerCase().includes(searchTerm);
        }
        return candidate.user?.name?.toLowerCase().includes(searchTerm);
      });
    }

    // Handle tab filtering
    if (activeTab === 2) {
      filteredData = filteredData.filter((elem) => elem.examStatus === "ended");
    } else if (activeTab === 3) {
      filteredData = filteredData.filter(
        (elem) =>
          elem.shortlistStatus === "Rejected" && elem.examStatus === "ended"
      );
    } else if (activeTab === 4) {
      filteredData = filteredData.filter(
        (elem) =>
          elem.examStatus === "ended" && Math.round(elem.marksScored) > 0
      );
    } else if (activeTab === 5) {
      filteredData = filteredData.filter(
        (elem) => elem.examStatus === "ended" && (elem.marksScored)===null
      );
    } else if (activeTab === 6) {
      filteredData = filteredData.filter(
        (elem) =>
          elem.examStatus === "ended" &&
        (elem.marksScored) === 0
      );
    }

    return filteredData;
  }, [filteredCandidates, activeTab, debounceSearchKey]);

  const { assignedArray, completedArray, Shortlisted } = useMemo(() => {
    const candidates = fetchCandidateList;

    if (!candidates || candidates === "user is not a part of organization")
      return { assignedArray: [], completedArray: [], Shortlisted: [] };

    return {
      assignedArray: candidates.filter((elem) => elem.examStatus !== "ended"),
      completedArray: candidates.filter(
        (elem) =>
          elem.examStatus === "ended" && elem.shortlistStatus !== "Shortlisted"
      ),
      Shortlisted: candidates.filter(
        (elem) =>
          elem.shortlistStatus === "Shortlisted" && elem.examStatus === "ended"
      ),
    };
  }, [fetchCandidateList]);

  const statusColor = useCallback((value) => {
    if (value < 30) {
      return "text-lightRed";
    } else if (value < 80) {
      return "text-goldenBrown";
    } else {
      return "text-forestGreen";
    }
  }, []);

  // Get unique exam IDs and their details
  const uniqueExams = useMemo(() => {
    if (!data) return [];
    const examsMap = new Map();

    data.forEach((item) => {
      if (item.exam && !examsMap.has(item.exam.id)) {
        examsMap.set(item.exam.id, {
          id: item.exam.id,
          name: item.exam.name,
          benchMarkScore: item.exam.benchMarkScore,
        });
      }
    });

    return Array.from(examsMap.values());
  }, [data]);

  // Get the exams to show in dropdown based on whether there's a title
  const dropdownExams = useMemo(() => {
    if (!uniqueExams) return [];

    // Create an "All Exam" option
    const allExamOption = { id: null, name: "All Exam" };

    if (title) {
      // If title exists, show "All Exam" and the exam matching the URL examId
      const matchingExam = uniqueExams.find(
        (exam) => exam.id === parseInt(examId)
      );
      return matchingExam ? [matchingExam] : [allExamOption];
    }
    // If no title, show "All Exam" and all exams
    return [allExamOption, ...uniqueExams];
  }, [uniqueExams, examId, title]);

  const getDropdownDisplayText = () => {
    if (!selectedExamId) return "All Exam";
    const selectedExam = uniqueExams.find((exam) => exam.id === selectedExamId);
    return selectedExam ? `${selectedExam.name}` : "All Exam";
  };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorPage />;
  if (!data || data.data === "user is not a part of organization")
    return <NoData />;

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "18px",
            padding: "16px",
            minWidth: "300px",
          },
        }}
      />
      <div className="mt-6 ml-0 md:ml-24 px-2 md:px-4 overflow-x-auto">
        <div className="flex w-full flex-col items-center justify-center gap-6 px-2 md:px-4">
          {/* <!-- header --> */}
          <div className="flex items-center gap-4">
            {icon && (
              <div className="mt-1" onClick={handleBackClick}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.002 12H5.00195"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.002 19L5.00195 12L12.002 5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            )}
            <div className="text-2xl font-semibold">Candidate List</div>
            {title && (
              <div className="flex items-center gap-2">
                <div className="mt-1">
                  <svg
                    width="6"
                    height="4"
                    viewBox="0 0 8 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.345953 3.28V0.592H7.96195V3.28H0.345953Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="text-2xl font-semibold">{title}</div>
              </div>
            )}
          </div>
          {/* <!-- benchmarks --> */}
          <div className="bg-whisperGray relative" style={{ width: "800px" }}>
            <div
              className="flex w-full items-center justify-between px-4 py-2 cursor-pointer"
              onClick={() => setShowExamDropdown(!showExamDropdown)}
            >
              <div className="text-sm">{getDropdownDisplayText()}</div>
              {!title && (
                <div>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`transform transition-transform duration-200 ${
                      showExamDropdown ? "rotate-180" : ""
                    } rounded-md`}
                  >
                    <path
                      d="M5.00195 7.5L10.002 12.5L15.002 7.5"
                      stroke="#191C45"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              )}
            </div>

            {showExamDropdown && (
              <div className="absolute top-full left-0 w-full bg-white shadow-lg rounded-md z-10">
                {!title &&
                  dropdownExams.map((exam) => (
                    <div
                      key={exam.id}
                      className="px-4 py-3 hover:bg-gray-100 cursor-pointer border-b last:border-b-0 text-sm"
                      onClick={() => {
                        setSelectedExamId(exam.id);
                        setShowExamDropdown(false);
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <span> {exam.name}</span>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          {/* <!-- filter parts --> */}

          <div className="flex w-full flex-col md:flex-row items-start md:items-center justify-between px-4 my-4 gap-4">
            {/* <!-- left part --> */}
            <div className="flex flex-wrap items-center gap-4 w-full md:w-auto">
              {/* <div
                className="flex items-center gap-2 rounded-full  px-4 py-2  bg-grayColor"
                style={{ border: "0.5px solid rgba(0, 0, 0, 0.5)" }}
              >
                <div className="text-sm whitespace-nowrap">All Filters</div>
                <div>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_5876_25593)">
                      <path
                        d="M18.3366 2.5H1.66992L8.33659 10.3833V15.8333L11.6699 17.5V10.3833L18.3366 2.5Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5876_25593">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0.00292969)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div> */}
              {/* </div> */}
              {/* <div
                className="flex items-center gap-2 rounded-full  px-4 py-2"
                style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
              >
                <div className="text-sm">Role</div>
                <div>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.00293 7.5L10.0029 12.5L15.0029 7.5"
                      stroke="#191C45"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div> */}
              <button
                className={`flex items-center gap-2 rounded-full  px-4 py-2   ${
                  activeTab === 1 ? "bg-grayColor" : "bg-white"
                }`}
                style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
                onClick={() => setActiveTab(1)}
              >
                <div className="text-sm">All</div>
                {/* <div>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0029 4L4.00293 12"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.00293 4L12.0029 12"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div> */}
              </button>
              {/* <button
                className={`rounded-full  px-4 py-2 text-sm  ${
                  activeTab === 2 ? "bg-grayColor" : "bg-white"
                }`}
                style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
                onClick={() => setActiveTab(2)}
              >
                Completed
              </button> */}
              <button
                className={`rounded-full  px-4 py-2 text-sm  ${
                  activeTab === 3 ? "bg-grayColor" : "bg-white"
                }`}
                style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
                onClick={() => setActiveTab(3)}
              >
                Rejected
              </button>
              <button
                className={`rounded-full  px-4 py-2 text-sm  ${
                  activeTab === 4 ? "bg-grayColor" : "bg-white"
                }`}
                style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
                onClick={() => setActiveTab(4)}
              >
                ReportCard Generated
              </button>
              <button
                className={`rounded-full  px-4 py-2 text-sm  ${
                  activeTab === 5 ? "bg-grayColor" : "bg-white"
                }`}
                style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
                onClick={() => setActiveTab(5)}
              >
                ReportCard Pending
              </button>
              <button
                className={`rounded-full  px-4 py-2 text-sm  ${
                  activeTab === 6 ? "bg-grayColor" : "bg-white"
                }`}
                style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
                onClick={() => setActiveTab(6)}
              >
                Not Attempted
              </button>
            </div>
            {/* <!-- right part --> */}
            <div className="flex items-center gap-4 w-full md:w-auto">
              <div className="flex items-center gap-2 rounded-md border border-black px-4 py-2">
                <div>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                      stroke="#808080"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.0001 14.0001L11.1001 11.1001"
                      stroke="#808080"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  className="text-sm text-grayish outline-none w-[70%]"
                  placeholder="Search with name or email "
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </div>
              <ReportCardGeneratedPopOver examId={selectedExamId} />
              {/* <button className="flex items-center justify-between gap-4 rounded-full bg-sunFlowerYellow px-4 py-2 w-full">
                <div className="text-[10px] flex items-center whitespace-nowrap">
                  Download list
                </div>
                <div className="flex items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
                      stroke="#191C45"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.8335 8.3335L10.0002 12.5002L14.1668 8.3335"
                      stroke="#191C45"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 12.5V2.5"
                      stroke="#191C45"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </button> */}
            </div>
          </div>

          {/* <!-- three column assessment --> */}
          <div className="grid w-full grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-6 xl:gap-8 p-4">
            {/* <!-- assign  --> */}
            <div
              className="bg-whisperGray px-2 py-2 rounded-md min-w-[300px]"
              style={{ minWidth: "400px" }}
            >
              {/* <!-- header --> */}
              <div className="flex items-center justify-between">
                <div className="text-xl font-semibold">
                  Assigned <span>({assignedArray.length})</span>
                </div>
                <button
                  className={`flex items-center gap-2 rounded-full px-4 py-2 ${
                    selectedExamId
                      ? "bg-sunFlowerYellow cursor-pointer"
                      : "bg-sunFlowerYellow opacity-50 cursor-not-allowed"
                  }`}
                  onClick={() => {
                    if (selectedExamId) {
                      const selectedExam = uniqueExams.find(
                        (exam) => exam.id === selectedExamId
                      );
                      navigate(
                        `/assessments/exam/${selectedExamId}/assigncandidate`,
                        {
                          state: {
                            title: selectedExam?.name || "Assign Candidate",
                            icon: true,
                          },
                        }
                      );
                    }
                  }}
                  disabled={!selectedExamId}
                >
                  <div className="text-md">Assign</div>
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_6233_21635)">
                        <path
                          d="M13.3356 17.5V15.8333C13.3356 14.9493 12.9844 14.1014 12.3593 13.4763C11.7342 12.8512 10.8863 12.5 10.0023 12.5H5.00228C4.11822 12.5 3.27038 12.8512 2.64526 13.4763C2.02013 14.1014 1.66895 14.9493 1.66895 15.8333V17.5"
                          stroke="#191C45"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.50228 9.16667C9.34323 9.16667 10.8356 7.67428 10.8356 5.83333C10.8356 3.99238 9.34323 2.5 7.50228 2.5C5.66133 2.5 4.16895 3.99238 4.16895 5.83333C4.16895 7.67428 5.66133 9.16667 7.50228 9.16667Z"
                          stroke="#191C45"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.835 6.6665V11.6665"
                          stroke="#191C45"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18.335 9.1665H13.335"
                          stroke="#191C45"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6233_21635">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(0.00195312)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </button>
              </div>
              <hr className="border-t border-black opacity-20 mt-2" />
              {/* <!-- body --> */}
              {assignedArray.map((elem) => {
                if (!elem?.user) return null;
                const userName = elem.user.name || "-";
                const userEmail = elem.user.email || "-";

                return (
                  <div
                    key={elem.id || Math.random()}
                    className="flex flex-col justify-center gap-4 mt-4"
                  >
                    <div className="flex flex-col justify-center gap-2">
                      <div className="flex items-center justify-between">
                        <div>{userName}</div>
                        <div>{elem.examStatus}</div>
                      </div>
                      <div className="flex items-center justify-between text-sm text-grayish">
                        <div className="text-sm text-grayish">{userEmail}</div>
                        <div className="text-sm text-grayish">
                          {elem.user.createdAt
                            ? moment(elem.user.createdAt).format("Do MMM YYYY")
                            : "-"}
                        </div>
                      </div>
                      <hr className="border-t border-black opacity-20" />
                    </div>
                  </div>
                );
              })}
            </div>

            {/* <!-- completed --> */}
            <div
              className="bg-yellowShade p-4 rounded-md min-w-[300px]"
              style={{ minWidth: "400px" }}
            >
              <div className="text-xl font-semibold">
                Completed <span>({completedArray.length})</span>
              </div>
              <hr className="border-t border-black opacity-20 mt-2" />
              {completedArray.map((elem) => {
                if (!elem?.user) return null;
                const userName = elem.user.name || "-";
                const userEmail = elem.user.email || "-";
                const mean = elem.exam?.benchMarkScore?.Mean;
                const displayName =
                  userName !== "-"
                    ? userName.split(" ").slice(0, 2).join(" ") +
                      (userName.split(" ").length > 3 ? "..." : "")
                    : "-";

                return (
                  <div
                    className="flex flex-col justify-center gap-2 mt-4"
                    key={elem.id || Math.random()}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        {Math.round(elem.marksScored) ? (
                          <NavLink
                            className="text-sm cursor-pointer underline"
                            to={`/exam/${elem.exam.id}/user/${elem.user.id}/report`}
                          >
                            {displayName}
                          </NavLink>
                        ) : (
                          <span className="text-sm">{displayName}</span>
                        )}
                        {Math.round(elem.marksScored) &&
                        elem.recommendStatus === "Recommended" &&
                        elem.shortlistStatus !== "Rejected" ? (
                          <button className="flex items-center gap-2 rounded-full border border-forestGreen px-4 py-1">
                            <div>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_6233_21709)">
                                  <path
                                    d="M4.66846 6.6665V14.6665"
                                    stroke="#3E721E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M10.0021 3.92016L9.33545 6.66683H13.2221C13.4291 6.66683 13.6333 6.71502 13.8184 6.80759C14.0035 6.90016 14.1646 7.03457 14.2888 7.20016C14.413 7.36576 14.4969 7.55799 14.5339 7.76165C14.571 7.9653 14.5601 8.17478 14.5021 8.3735L12.9488 13.7068C12.868 13.9838 12.6996 14.2271 12.4688 14.4002C12.238 14.5733 11.9573 14.6668 11.6688 14.6668H2.66878C2.31516 14.6668 1.97602 14.5264 1.72597 14.2763C1.47592 14.0263 1.33545 13.6871 1.33545 13.3335V8.00016C1.33545 7.64654 1.47592 7.3074 1.72597 7.05735C1.97602 6.80731 2.31516 6.66683 2.66878 6.66683H4.50878C4.75684 6.6667 4.99994 6.59737 5.21075 6.46664C5.42157 6.33592 5.59173 6.14897 5.70212 5.92683L8.00212 1.3335C8.3165 1.33739 8.62594 1.41228 8.90732 1.55256C9.1887 1.69284 9.43473 1.8949 9.62705 2.14363C9.81937 2.39236 9.95299 2.68134 10.0179 2.98896C10.0829 3.29659 10.0775 3.61492 10.0021 3.92016Z"
                                    stroke="#3E721E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_6233_21709">
                                    <rect
                                      width="16"
                                      height="16"
                                      fill="white"
                                      transform="translate(0.00195312)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="text-sm font-semibold text-forestGreen">
                              Recommended
                            </div>
                          </button>
                        ) : (
                          ""
                        )}
                        {elem.shortlistStatus === "Rejected" && (
                          <button className="flex items-center gap-2 rounded-full border border-lightRed px-4 py-1">
                            <div className="text-sm font-semibold text-lightRed">
                              Rejected
                            </div>
                          </button>
                        )}
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => changeStatus(elem.id, "Shortlisted")}
                          disabled={elem.shortlistStatus === "Shortlisted"}
                          className={
                            elem.shortlistStatus === "Shortlisted"
                              ? "cursor-not-allowed opacity-50"
                              : ""
                          }
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.001 3H5.00098C3.89641 3 3.00098 3.89543 3.00098 5V19C3.00098 20.1046 3.89641 21 5.00098 21H19.001C20.1055 21 21.001 20.1046 21.001 19V5C21.001 3.89543 20.1055 3 19.001 3Z"
                              fill="#3E721E"
                            />
                            <path
                              d="M9.00098 12L11.001 14L15.001 10"
                              fill="#3E721E"
                            />
                            <path
                              d="M9.00098 12L11.001 14L15.001 10"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                        <button
                          onClick={() => changeStatus(elem.id, "Rejected")}
                          disabled={
                            elem.shortlistStatus === "Rejected" ||
                            elem.recommendStatus== "Not Recommended"
                          }
                          className={
                            elem.shortlistStatus === "Rejected" ||
                            elem.recommendStatus == "Not Recommended"
                              ? "cursor-not-allowed opacity-50"
                              : ""
                          }
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.001 3H5.00098C3.89641 3 3.00098 3.89543 3.00098 5V19C3.00098 20.1046 3.89641 21 5.00098 21H19.001C20.1055 21 21.001 20.1046 21.001 19V5C21.001 3.89543 20.1055 3 19.001 3Z"
                              fill="#E04C4C"
                            />
                            <path
                              d="M9.00098 9L15.001 15"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M15.001 9L9.00098 15"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center justify-between text-sm text-grayish">
                      <div className="flex items-center gap-2 text-sm text-grayish">
                        {userEmail}
                      </div>

                      {elem.marksScored === null ? (
                        "ReportCard Pending"
                      ) : (elem.marksScored) === 0 ? (
                        "Not Attempted"
                      ) : (
                        <div className="flex items-center gap-1 text-sm text-grayish">
                          <div>Kodiva Score:</div>
                          <div className={statusColor(mean)}>
                            {Math.round(elem.marksScored)}{" "}
                          </div>
                        </div>
                      )}
                    </div>
                    <hr className="border-t border-black opacity-20" />
                  </div>
                );
              })}
            </div>
            {/* <!-- shortlisted --> */}

            <div
              className="bg-lightgreenColor p-4 rounded-md min-w-[300px]"
              style={{ minWidth: "400px" }}
            >
              <div className="text-xl font-semibold">
                Shortlisted<span>({Shortlisted.length})</span>
              </div>
              <hr className="border-t border-black opacity-20 mt-4" />
              {Shortlisted.map((elem) => {
                if (!elem?.user) return null;
                const userName = elem.user.name || "-";
                const userEmail = elem.user.email || "-";
                const mean = elem.exam?.benchMarkScore?.Mean ?? 0;
                const displayName =
                  userName !== "-"
                    ? userName.split(" ").slice(0, 2).join(" ") +
                      (userName.split(" ").length > 3 ? "..." : "")
                    : "-";

                return (
                  <div
                    className="flex flex-col justify-center gap-2 mt-4"
                    key={elem.id || Math.random()}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        {Math.round(elem.marksScored) ? (
                          <NavLink
                            className="text-sm cursor-pointer underline"
                            to={`/exam/${elem.exam.id}/user/${elem.user.id}/report`}
                          >
                            {displayName}
                          </NavLink>
                        ) : (
                          <span className="text-sm">{displayName}</span>
                        )}
                        {Math.round(elem.marksScored) &&
                        elem.recommendStatus &&
                        elem.shortlistStatus !== "Rejected" ? (
                          <button className="flex items-center gap-2 rounded-full border border-forestGreen px-4 py-1">
                            <div>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_6233_21709)">
                                  <path
                                    d="M4.66846 6.6665V14.6665"
                                    stroke="#3E721E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M10.0021 3.92016L9.33545 6.66683H13.2221C13.4291 6.66683 13.6333 6.71502 13.8184 6.80759C14.0035 6.90016 14.1646 7.03457 14.2888 7.20016C14.413 7.36576 14.4969 7.55799 14.5339 7.76165C14.571 7.9653 14.5601 8.17478 14.5021 8.3735L12.9488 13.7068C12.868 13.9838 12.6996 14.2271 12.4688 14.4002C12.238 14.5733 11.9573 14.6668 11.6688 14.6668H2.66878C2.31516 14.6668 1.97602 14.5264 1.72597 14.2763C1.47592 14.0263 1.33545 13.6871 1.33545 13.3335V8.00016C1.33545 7.64654 1.47592 7.3074 1.72597 7.05735C1.97602 6.80731 2.31516 6.66683 2.66878 6.66683H4.50878C4.75684 6.6667 4.99994 6.59737 5.21075 6.46664C5.42157 6.33592 5.59173 6.14897 5.70212 5.92683L8.00212 1.3335C8.3165 1.33739 8.62594 1.41228 8.90732 1.55256C9.1887 1.69284 9.43473 1.8949 9.62705 2.14363C9.81937 2.39236 9.95299 2.68134 10.0179 2.98896C10.0829 3.29659 10.0775 3.61492 10.0021 3.92016Z"
                                    stroke="#3E721E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_6233_21709">
                                    <rect
                                      width="16"
                                      height="16"
                                      fill="white"
                                      transform="translate(0.00195312)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="text-sm font-semibold text-forestGreen">
                              Recommended
                            </div>
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => changeStatus(elem.id, "Shortlisted")}
                          disabled={elem.shortlistStatus === "Shortlisted"}
                          className={
                            elem.shortlistStatus === "Shortlisted"
                              ? "cursor-not-allowed opacity-50"
                              : ""
                          }
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                              fill="#CCCCCC"
                            />
                            <path d="M9 12L11 14L15 10" fill="#CCCCCC" />
                            <path
                              d="M9 12L11 14L15 10"
                              stroke="#808080"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                        <button
                          onClick={() => changeStatus(elem.id, "Rejected")}
                          disabled={elem.shortlistStatus === "Rejected"}
                          className={
                            elem.shortlistStatus === "Rejected"
                              ? "cursor-not-allowed opacity-50"
                              : ""
                          }
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.001 3H5.00098C3.89641 3 3.00098 3.89543 3.00098 5V19C3.00098 20.1046 3.89641 21 5.00098 21H19.001C20.1055 21 21.001 20.1046 21.001 19V5C21.001 3.89543 20.1055 3 19.001 3Z"
                              fill="#E04C4C"
                            />
                            <path
                              d="M9.00098 9L15.001 15"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M15.001 9L9.00098 15"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center justify-between text-sm text-grayish">
                      <div className="flex items-center gap-2 text-sm text-grayish">
                        {userEmail}
                      </div>

                      {elem.marksScored === null ? (
                        "ReportCard Pending"
                      ) : (elem.marksScored) === 0 ? (
                        "Not Attempted"
                      ) : (
                        <div className="flex items-center gap-1 text-sm text-grayish">
                          <div>Kodiva Score:</div>
                          <div className={statusColor(mean)}>
                            {Math.round(elem.marksScored)}{" "}
                          </div>
                        </div>
                      )}
                    </div>

                    <hr className="border-t border-black opacity-20 mt-2" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CandidateList;
