import { useState,useEffect, useMemo } from "react";

import assessmentList from "../../apis/assessmentListApi";
import useStore from "../../store/useStore";
import { useQuery } from "react-query";
import Loading from "../../common/Loading";
import ErrorPage from "../../common/ErrorPage";

import PopOver from "./popOver";
import copyLinkApis from "../../apis/copyLink";
import toast, { Toaster } from "react-hot-toast";
import NoData from "../../common/NoData";
const Assessment = () => {
  const [activeTab, setActiveTab] = useState(1); 



  const token=useStore((state)=>state.token);
  const fetchAsessmentList= async()=>{
    return await assessmentList.show(token, null);
  }
  const {data,isLoading,isError}=useQuery({
    queryKey:["assessmentList",activeTab],
    queryFn:fetchAsessmentList,
      enabled:!!activeTab,
  })
  const handleCopyLink=async(examId)=>{
    try {
      const data=await copyLinkApis.show({token,examId});
      await navigator.clipboard.writeText(data?.data?.link);
      toast.success('link copied to clipboard !');

    } catch (error) {
      console.log(error);
      toast.error("link not copied");
    }
  }
  const statusColor = useMemo(() => ({
    InProgress: 'text-blueAccent',
    Published: 'text-greenAccent',
    Incomplete: 'text-goldenBrown',
    Ended: 'text-greenAccent',
  }), []);

if(isLoading) return <Loading/>
if(isError) return <ErrorPage />
 if(!data|| data.data==="user is not a part of organization") return <NoData/>
  return (
    // <div className="flex">
    // <Header />
    <>
    
     <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "18px",
            padding: "16px",
            minWidth: "300px",
          },
        }}
      />
    
      <div className="flex  flex-col items-center justify-center gap-4 mt-2"
        style={{ width: "800px" }}>
        {/* <!-- heading --> */}
        <div className="text-2xl font-semibold">Assessment List</div>
        <nav className="flex w-full items-center justify-between mt-4">
          {/* <!-- left side --> */}
          <div className="flex items-center gap-4">
          
            {/* <button className={` flex items-center gap-2 rounded-full  px-6 py-2 text-sm ${activeTab===1?'bg-grayColor':'bg-white'}`} 
            onClick={()=>setActiveTab(1)} 
            style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }}>
              <div className="text-sm">All</div>
             
            </button>
            <button className={`rounded-full  px-4 py-2 text-sm ${activeTab===2?'bg-grayColor':'bg-white'}`} 
            onClick={()=>setActiveTab(2)} 
            style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }}>
              Recent
            </button>
            <button className={`rounded-full  px-4 py-2 text-sm ${activeTab===3?'bg-grayColor':'bg-white'}`} onClick={()=>setActiveTab(3)}  style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }}>
              Completed
            </button>
            <button className={`rounded-full  px-4 py-2 text-sm ${activeTab===4?'bg-grayColor':'bg-white'}`} onClick={()=>setActiveTab(4)}  style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }}>
              InProgress
            </button> */}
          </div>

          {/* <!-- right side --> */}
          {/* <button className="flex items-center gap-1 rounded-full bg-yellowColor px-4 py-2">
            <div className="text-sm">Create Assessment</div>
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5013 1.66699H7.5013C7.04106 1.66699 6.66797 2.04009 6.66797 2.50033V4.16699C6.66797 4.62723 7.04106 5.00033 7.5013 5.00033H12.5013C12.9615 5.00033 13.3346 4.62723 13.3346 4.16699V2.50033C13.3346 2.04009 12.9615 1.66699 12.5013 1.66699Z"
                  stroke="#191C45"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.66829 3.33301H5.00163C4.5596 3.33301 4.13568 3.5086 3.82312 3.82116C3.51056 4.13372 3.33496 4.55765 3.33496 4.99967V16.6663C3.33496 17.1084 3.51056 17.5323 3.82312 17.8449C4.13568 18.1574 4.5596 18.333 5.00163 18.333H15.0016C15.4437 18.333 15.8676 18.1574 16.1801 17.8449C16.4927 17.5323 16.6683 17.1084 16.6683 16.6663V16.2497"
                  stroke="#191C45"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.335 3.33301H15.0016C15.2939 3.33331 15.581 3.41046 15.834 3.55673C16.087 3.70299 16.2972 3.91323 16.4433 4.16634"
                  stroke="#191C45"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.3516 8.00868C15.5141 7.84617 15.7071 7.71726 15.9194 7.62931C16.1317 7.54136 16.3593 7.49609 16.5891 7.49609C16.819 7.49609 17.0465 7.54136 17.2589 7.62931C17.4712 7.71726 17.6641 7.84617 17.8266 8.00868C17.9891 8.17119 18.118 8.36412 18.206 8.57645C18.2939 8.78878 18.3392 9.01636 18.3392 9.24618C18.3392 9.47601 18.2939 9.70358 18.206 9.91591C18.118 10.1282 17.9891 10.3212 17.8266 10.4837L14.1266 14.167L10.835 15.0003L11.66 11.7087L15.3516 8.00868Z"
                  stroke="#191C45"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.66797 15H7.5013"
                  stroke="#191C45"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </button> */}
        </nav>
        {/* <!-- data will come --> */}
        <div className="flex w-full flex-col justify-center mt-4 gap-4">
        { data &&data.map((elem)=>(
          <div key={elem.examId} className="flex w-full flex-col justify-center ">
          <div className="flex flex-col justify-center gap-3">
            {/* <!-- for each data -->

             <!-- header part --> */}
            <div className="flex w-full items-center justify-between">
              {/* <!-- left part --> */}
              <div className="flex items-center gap-2">
                <div>{elem.examName}</div>
                <div>
                  <svg
                    width="1"
                    height="20"
                    viewBox="0 0 1 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.00146484"
                      width="1"
                      height="20"
                      rx="0.5"
                      fill="#383838"
                    />
                  </svg>
                </div>
                <div>{elem.examRole}</div>
              </div>
              {/* <!-- right part --> */}
              <div className="flex items-center gap-2">
                
                
                <button className="flex items-center gap-2 rounded-full bg-yellowColor px-4 py-2" onClick={()=>handleCopyLink(elem.examId)}>
                  <div className="text-sm">Copy Link</div>
                  <div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_5876_25905)">
                        <path
                          d="M13.3348 6H7.3348C6.59842 6 6.00146 6.59695 6.00146 7.33333V13.3333C6.00146 14.0697 6.59842 14.6667 7.3348 14.6667H13.3348C14.0712 14.6667 14.6681 14.0697 14.6681 13.3333V7.33333C14.6681 6.59695 14.0712 6 13.3348 6Z"
                          stroke="#191C45"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.33496 9.99967H2.66829C2.31467 9.99967 1.97553 9.8592 1.72549 9.60915C1.47544 9.3591 1.33496 9.01996 1.33496 8.66634V2.66634C1.33496 2.31272 1.47544 1.97358 1.72549 1.72353C1.97553 1.47348 2.31467 1.33301 2.66829 1.33301H8.66829C9.02192 1.33301 9.36105 1.47348 9.6111 1.72353C9.86115 1.97358 10.0016 2.31272 10.0016 2.66634V3.33301"
                          stroke="#191C45"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5876_25905">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0.00146484)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </button>
               <PopOver  examId={elem.examId} title={elem.examName}   />
              </div>
            </div>

            {/* <!-- footer part --> */}
            <div className="flex w-full items-center justify-between">
              {/* <!-- left part --> */}
              <div className="flex items-center gap-2">
                {/* <div className={`text-sm text-grayStroke  ${statusColor[elem.examStatus]}`}>{elem.examStatus==="Ended"?"Complete":elem.examStatus}</div> */}
                <div className="text-sm text-grayStroke">{elem.createdAt}</div>
                <div>
                  <svg
                    width="1"
                    height="20"
                    viewBox="0 0 1 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.00146484"
                      width="1"
                      height="20"
                      rx="0.5"
                      fill="#383838"
                    />
                  </svg>
                </div>
                <div className="text-sm text-grayStroke">
                  Created By <span>{elem.createdBy}</span>
                </div>
              </div>
              {/* <!-- right part --> */}
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-1 text-sm text-grayStroke ">
                <div>{elem.totalAttended||0}</div>
                <span>attended</span>
                 
                  
                </div>
                <div className="flex items-center gap-1 text-sm text-grayStroke ">
                <div>{elem.totalAssigned||'Not'}</div>
                <span>assigned</span>
                </div>
              </div>
            </div>
            <hr className="border-t border-black opacity-20" />
          </div>
        </div>
        ))}
        </div>
      </div>
    
  </>
  );
};
export default Assessment;
