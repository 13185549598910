import { useState, useCallback, useMemo, useEffect } from "react";
import { useMutation } from "react-query";
import assignCandidateApi from "../../apis/AssignCandidateApi";
import useStore from "../../store/useStore";
import toast, { Toaster } from "react-hot-toast";
import Loading from "../../common/Loading";
import ErrorPage from "../../common/ErrorPage";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import assignCandidateExamApi from "../../apis/AssignedCandidateWithExamApi";
import useDebounce from "../../hooks/useDebounce";
import NoData from "../../common/NoData";
import SettingPopOver from "./SettingPopOver";
import copyLinkApis from "../../apis/copyLink";
import ResendEmail from "../../apis/ResendEmail";
import ResetApis from "../../apis/ResetApi";
const AssignCandidate = () => {
  const [email, setEmail] = useState("");
  const [examLink, setExamLink] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const token = useStore((state) => state.token);

  const { examId } = useParams() || {};

  const navigate = useNavigate();
  const location = useLocation();
  const { title } = location.state || {};

  const fetchdata = async () => {
    try {
      const response = await assignCandidateExamApi.show({ token, examId });
      if (!response) {
        throw new Error("No response received");
      }
      return response.data || [];
    } catch (error) {
      console.error("Fetch error:", error);
      throw new Error(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch candidates"
      );
    }
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ["assignedCandidatewithExam", examId],
    queryFn: fetchdata,
    refetchOnWindowFocus: true,
  });

  const queryClient = useQueryClient();
  const debounceSearchKey = useDebounce(searchKey);
  const mutation = useMutation(
    ({ token, email, examId }) =>
      assignCandidateApi.fetch({ token, email, examId }),
    {
      onSuccess: () => {
        toast.success("Candidate assigned successfully");

        setEmail("");
        queryClient.invalidateQueries("assignedCandidatewithExam");
      },
      onError: (error) => {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";
        toast.error(`Error assigning candidate: ${errorMessage}`);
        console.error("Assignment error:", error);
      },
    }
  );
  const resendMuttation = useMutation(
    ({ token, email, examId }) => ResendEmail.show({ token, email, examId }),
    {
      onSuccess: () => {
        toast.success("Email resent successfully");
        setEmail("");
        queryClient.invalidateQueries("assignedCandidatewithExam");
      },
      onError: (error) => {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";
        toast.error(`Error assigning candidate: ${errorMessage}`);
        console.error("Resend error:", error);
      },
    }
  );

  const resentMuttation = useMutation(
    ({ token, examUserId, examId }) => ResetApis.fetch({ examUserId ,token,examId}),
    {
      onSuccess: (data) => {
        if(data===false){
          toast.error("Exam is not reset to the user")
        }
        else{
        toast.success("Exam reset to user  successfully");
        setEmail("");
        queryClient.invalidateQueries("assignedCandidatewithExam");
        }
      },
      onError: (error) => {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";
        toast.error(`Error assigning candidate: ${errorMessage}`);
        console.error("Resend error:", error);
      },
    }
  );
  const handleCopyLink = async (examId) => {
    try {
      const response = await copyLinkApis.show({ token, examId });
      const linkValue = response?.data?.link || "";
      setExamLink(linkValue);
    } catch (error) {
      console.error(error);
      toast.error("Failed to get link");
      setExamLink("");
    }
  };

  useEffect(() => {
    handleCopyLink(examId);
  }, []);
  const handleCopy = () => {
    navigator.clipboard.writeText(examLink);
    toast.success("link copied to clipboard !");
  };

  const handleChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleAddUser = useCallback(() => {
    if (email && /^\S+@\S+\.\S+$/.test(email)) {
      mutation.mutate({ token, email, examId });
    } else {
      toast.error("Invalid email address");
    }
  }, [email, token, mutation, examId]);
  const resendAddUser = useCallback(
    (email) => {
      if (email && /^\S+@\S+\.\S+$/.test(email)) {
        resendMuttation.mutate({ token, email, examId });
      } else {
        toast.error("Invalid email address");
      }
    },
    [email, token,resendMuttation, examId]
  );
  const resentAddUser = useCallback(
    (examUserId) => {
      if (examUserId  && examId) {
        resentMuttation.mutate({ token, examUserId, examId });
      } else {
        toast.error("Invalid examid ");
      }
    },
    [ token, resentMuttation, examId]
  );

  const filterCandidates = useMemo(() => {
    if (!data || !debounceSearchKey.trim()) return data;

    const searchKey = debounceSearchKey.trim().toLowerCase();
    const isEmailSearch = searchKey.includes("@");

    return data.filter((candidate) => {
      if (isEmailSearch) {
        return (
          candidate.email && candidate.email.toLowerCase().includes(searchKey)
        );
      } else {
        return (
          candidate.name && candidate.name.toLowerCase().includes(searchKey)
        );
      }
    });
  }, [data, debounceSearchKey]);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorPage />;

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "18px",
            padding: "16px",
            minWidth: "300px",
          },
        }}
      />

      <div className="mt-4 flex flex-col items-center justify-center gap-8">
        <div className="flex items-center gap-2">
          <div className="text-xl font-bold">Assign Candidates</div>
        </div>
        <div
          className="flex  flex-col justify-center gap-4"
          style={{ width: "800px" }}
        >
          <div>
            <div className="flex items-center justify-between bg-whisperGray px-2 py-4 rounded-md">
              <div className="text-sm">{title}</div>
              {/* <button className="border rounded-full px-4 py-2 border-royalBlue text-royalBlue flex items-center gap-2" onClick={()=>navigate(`/assessments/exam/${examId}/assessmentdetail`)} disabled>
                  <div className="text-sm">View</div>
                  <div>
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.66797 11.3332L11.3346 4.6665"
                        stroke="#4D80F4"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.66797 4.6665H11.3346V11.3332"
                        stroke="#4D80F4"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </button> */}
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between bg-whisperGray px-4 py-2 rounded-md">
              <div className="text-sm text-grayStroke">{examLink}</div>
              <button
                className="border rounded-full px-4 py-2 border-royalBlue text-royalBlue flex items-center gap-2"
                onClick={() => handleCopy()}
              >
                <div className="text-sm">Copy Link</div>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_5876_24013)">
                      <path
                        d="M16.6679 7.5H9.16789C8.24741 7.5 7.50122 8.24619 7.50122 9.16667V16.6667C7.50122 17.5871 8.24741 18.3333 9.16789 18.3333H16.6679C17.5884 18.3333 18.3346 17.5871 18.3346 16.6667V9.16667C18.3346 8.24619 17.5884 7.5 16.6679 7.5Z"
                        stroke="#4D80F4"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.16797 12.4998H3.33464C2.89261 12.4998 2.46868 12.3242 2.15612 12.0117C1.84356 11.6991 1.66797 11.2752 1.66797 10.8332V3.33317C1.66797 2.89114 1.84356 2.46722 2.15612 2.15466C2.46868 1.8421 2.89261 1.6665 3.33464 1.6665H10.8346C11.2767 1.6665 11.7006 1.8421 12.0131 2.15466C12.3257 2.46722 12.5013 2.89114 12.5013 3.33317V4.1665"
                        stroke="#4D80F4"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5876_24013">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0.0012207)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <div>
            <div className="text-center font-semibold py-4">Or</div>
            <div className="flex items-center justify-between py-2">
              <div className="text-sm w-5/6 border flex items-center justify-between px-4 py-2 rounded-full border-grayStroke text-grayStroke">
                <input
                  className="text-sm w-full outline-none"
                  placeholder="Add student emails "
                  name="email"
                  value={email}
                  onChange={handleChange}
                />
                {/* <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5012 12.5V15.8333C17.5012 16.2754 17.3256 16.6993 17.0131 17.0118C16.7005 17.3244 16.2766 17.5 15.8346 17.5H4.16789C3.72586 17.5 3.30194 17.3244 2.98938 17.0118C2.67682 16.6993 2.50122 16.2754 2.50122 15.8333V12.5"
                        stroke="#383838"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.1678 6.66667L10.0011 2.5L5.83447 6.66667"
                        stroke="#383838"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0012 2.5V12.5"
                        stroke="#383838"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div> */}
              </div>
              <button
                className={`flex items-center gap-2 bg-yellowColor px-4 py-2 rounded-full ${
                  mutation.isLoading || !email
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                onClick={handleAddUser}
                disabled={mutation.isLoading || !email}
              >
                <div className="text-sm">Add</div>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_5876_24066)">
                      <path
                        d="M13.3346 17.5V15.8333C13.3346 14.9493 12.9834 14.1014 12.3583 13.4763C11.7332 12.8512 10.8854 12.5 10.0013 12.5H5.0013C4.11725 12.5 3.2694 12.8512 2.64428                    13.4763C2.01916 14.1014 1.66797 14.9493 1.66797 15.8333V17.5"
                        stroke="#191C45"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.5013 9.16667C9.34225 9.16667 10.8346 7.67428 10.8346 5.83333C10.8346 3.99238 9.34225 2.5 7.5013 2.5C5.66035 2.5 4.16797 3.99238 4.16797 5.83333C4.16797 7.67428 5.66035 9.16667 7.5013 9.16667Z"
                        stroke="#191C45"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.8345 6.6665V11.6665"
                        stroke="#191C45"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.3345 9.1665H13.3345"
                        stroke="#191C45"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5876_24066">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0.0012207)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between bg-softGray px-4 py-4 rounded-md">
              <div className="text-sm">
                The assignment will expire <strong> 7 days </strong> from the
                date of assigning.You can change the settings.
              </div>
              {/* <SettingPopOver /> */}
            </div>
          </div>
          <div>
            <div className="mt-2">
              <div className="flex flex-col gap-2 bg-whisperGray px-4 py-2 rounded-md">
                <div>
                  <div className=" text-md  text-grayStroke flex justify-between items-center">
                    <div className="font-semibold">
                      Assigned ({data.length || 0})
                    </div>
                    <div className="flex items-center gap-2 rounded-md border border-black px-4 py-2">
                      <div>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                            stroke="#808080"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.0001 14.0001L11.1001 11.1001"
                            stroke="#808080"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        className="text-sm text-grayish outline-none bg-whisperGray"
                        placeholder="Search with email or name"
                        onChange={(e) => setSearchKey(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* <hr className="border-t border-black opacity-20 mt-2" /> */}
                </div>
                <table className="w-full  table-auto border-collapse mt-2">
                  <thead className=" text-center text-gray-700 border-b border-black/[0.5] py-2 ">
                    <tr>
                      <th className="py-2 px-2 text-sm ">S/R</th>
                      <th className="py-3  text-sm">Email</th>
                      <th className="py-3 px-2 text-sm">Name</th>
                      <th className="py-3 px-2 text-sm">Status</th>
                      {/* <th className="py-3 px-2 text-sm">Mobile No.</th> */}
                      <th className="py-3 px-2 text-sm whitespace-nowrap">
                        Reset/Resend Status
                      </th>
                      <th className="py-3 px-2 text-sm">Date</th>
                    </tr>
                  </thead>

                  <tbody>
                    {filterCandidates &&
                      filterCandidates.map((elem, index) => (
                        <tr key={index} className="border-b border-black/[0.2]">
                          <td className="text-sm text-gray-600 py-2  text-center">
                            {index + 1}
                          </td>
                          <td className="text-sm text-gray-600 py-2  text-center">
                            {elem.email}
                          </td>
                          <td className="text-sm text-gray-600 py-2 text-center ">
                            {elem.name || "--"}
                          </td>
                          <td className=" py-2 text-center text-md text-darkblue whitespace-nowrap">
                           
                              {elem.examStatus}
              
                          </td>
                          <td className="text-sm text-gray-600 px-2 text-center">
                            {(elem.examStatus === "Mail Sent" ||
                              elem.examStatus === "enrolled") && (
                              <div className="flex justify-center">
                                <button
                                  className="flex items-center gap-2 border border-royalBlue rounded-full px-2 py-1"
                                  onClick={() => resendAddUser(elem.email)}
                                >
                                  <div
                                    className="whitespace-nowrap text-royalBlue"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Resend
                                  </div>

                                  <div>
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.5 7L10 4.5L7.5 2"
                                        stroke="#4D80F4"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M10 4.5H4.75C4.02065 4.5 3.32118 4.78973 2.80546 5.30546C2.28973 5.82118 2 6.52065 2 7.25C2 7.61114 2.07113 7.96873 2.20933 8.30238C2.34753 8.63602 2.5501 8.93918 2.80546 9.19454C3.32118 9.71027 4.02065 10 4.75 10H6.5"
                                        stroke="#4D80F4"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </button>
                              </div>
                            )}
                             {(elem.examStatus === "ended" ||
                              elem.examStatus === "started") && (
                              <div className="flex justify-center">
                                <button
                                  className="flex items-center gap-2 border border-royalBlue rounded-full px-2 py-1"
                                  onClick={() =>resentAddUser(elem.id)}
                                >
                                  <div
                                    className="whitespace-nowrap text-royalBlue"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Reset
                                  </div>

                                  <div>
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.5 7L10 4.5L7.5 2"
                                        stroke="#4D80F4"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M10 4.5H4.75C4.02065 4.5 3.32118 4.78973 2.80546 5.30546C2.28973 5.82118 2 6.52065 2 7.25C2 7.61114 2.07113 7.96873 2.20933 8.30238C2.34753 8.63602 2.5501 8.93918 2.80546 9.19454C3.32118 9.71027 4.02065 10 4.75 10H6.5"
                                        stroke="#4D80F4"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </button>
                              </div>
                            )}
                          </td>

                          <td className="text-sm text-gray-600 py-2 text-center whitespace-nowrap">
                            {moment(elem.createdAt).format("Do MMM  YYYY")}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AssignCandidate;
