import { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Link, useNavigate} from "react-router-dom";

const PopOver = ({examId,title}) => {
  const [anchorEl, setAnchorEl] = useState(null);
 const navigate=useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <button aria-describedby={id} variant="contained" onClick={handleClick}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0015 13C12.5537 13 13.0015 12.5523 13.0015 12C13.0015 11.4477 12.5537 11 12.0015 11C11.4492 11 11.0015 11.4477 11.0015 12C11.0015 12.5523 11.4492 13 12.0015 13Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.0015 6C12.5537 6 13.0015 5.55228 13.0015 5C13.0015 4.44772 12.5537 4 12.0015 4C11.4492 4 11.0015 4.44772 11.0015 5C11.0015 5.55228 11.4492 6 12.0015 6Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.0015 20C12.5537 20 13.0015 19.5523 13.0015 19C13.0015 18.4477 12.5537 18 12.0015 18C11.4492 18 11.0015 18.4477 11.0015 19C11.0015 19.5523 11.4492 20 12.0015 20Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography className="p-4 flex flex-col gap-4">
          <button onClick={()=>navigate(`exam/${examId}/assigncandidate`,{state:{title:title}})}  className="flex items-center gap-4 hover:bg-grayColor px-4 py-2 rounded-full">
               <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3332 17.5V15.8333C13.3332 14.9493 12.982 14.1014 12.3569 13.4763C11.7317 12.8512 10.8839 12.5 9.99984 12.5H4.99984C4.11578 12.5 3.26794 12.8512 2.64281 13.4763C2.01769 14.1014 1.6665 14.9493 1.6665 15.8333V17.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.49984 9.16667C9.34079 9.16667 10.8332 7.67428 10.8332 5.83333C10.8332 3.99238 9.34079 2.5 7.49984 2.5C5.65889 2.5 4.1665 3.99238 4.1665 5.83333C4.1665 7.67428 5.65889 9.16667 7.49984 9.16667Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.8335 6.66699V11.667"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.3335 9.16699H13.3335"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              </div>
                <div className="text-md">Assign Candidates</div>
          </button>
          {/* <button className="flex items-center gap-4 hover:bg-grayColor px-4 py-2 rounded-full" disabled>
                <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5880_21546)">
                  <path
                    d="M9.1665 3.33301H3.33317C2.89114 3.33301 2.46722 3.5086 2.15466 3.82116C1.8421 4.13372 1.6665 4.55765 1.6665 4.99967V16.6663C1.6665 17.1084 1.8421 17.5323 2.15466 17.8449C2.46722 18.1574 2.89114 18.333 3.33317 18.333H14.9998C15.4419 18.333 15.8658 18.1574 16.1783 17.8449C16.4909 17.5323 16.6665 17.1084 16.6665 16.6663V10.833"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.4165 2.0832C15.748 1.75168 16.1977 1.56543 16.6665 1.56543C17.1353 1.56543 17.585 1.75168 17.9165 2.0832C18.248 2.41472 18.4343 2.86436 18.4343 3.3332C18.4343 3.80204 18.248 4.25168 17.9165 4.5832L9.99984 12.4999L6.6665 13.3332L7.49984 9.99986L15.4165 2.0832Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5880_21546">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
                 </svg>
                 </div>
                  <div className="text-md">Edit Assessment</div>
          </button> */}
          <button onClick={()=>navigate(`exam/${examId}/candidate`,{state:{examId:examId,title:title,icon:true}})}  className="flex items-center gap-4 hover:bg-grayColor px-4 py-2 rounded-full">
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3332 17.5V15.8333C13.3332 14.9493 12.982 14.1014 12.3569 13.4763C11.7317 12.8512 10.8839 12.5 9.99984 12.5H4.99984C4.11578 12.5 3.26794 12.8512 2.64281 13.4763C2.01769 14.1014 1.6665 14.9493 1.6665 15.8333V17.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.49984 9.16667C9.34079 9.16667 10.8332 7.67428 10.8332 5.83333C10.8332 3.99238 9.34079 2.5 7.49984 2.5C5.65889 2.5 4.1665 3.99238 4.1665 5.83333C4.1665 7.67428 5.65889 9.16667 7.49984 9.16667Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.3335 17.5001V15.8334C18.3329 15.0948 18.0871 14.3774 17.6346 13.7937C17.1821 13.2099 16.5486 12.793 15.8335 12.6084"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.3335 2.6084C14.0505 2.79198 14.686 3.20898 15.1399 3.79366C15.5937 4.37833 15.84 5.09742 15.84 5.83757C15.84 6.57771 15.5937 7.2968 15.1399 7.88147C14.686 8.46615 14.0505 8.88315 13.3335 9.06673"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="text-md">View Candidates</div>
          </button>
        </Typography>
      </Popover>
    </>
  );
};
export default PopOver;
